<template>
  <div class="card-maid d-flex flex-column justify-content-between card-skeleton">
    <div>
      <div>
        <!-- imagecover -->
        <!-- <b-img :src="imageCover" class="d-none d-md-block cover-image"></b-img> -->
        <b-img
          alt="Maid Cover"
          :src="maid_cover"
          class="d-none d-md-block cover-image"
        ></b-img>
        <div
          class="maid-detail maid-detail-top mb-3 mb-md-0"
        >
          <div class="maid-detail-img">
            <Skeleton width="2rem" height="2rem" circle :loading="true"></Skeleton>
          </div>
          <div class="maid-detail-info overflow-hidden" style="width: 100%">
            <Skeleton width="100%" height="2rem" :loading="true"></Skeleton>
          </div>
        </div>

        <div
          class="maid-detail-info" style="width: 100%; padding: 1.2rem; padding-top: 0;"
        >
          <Skeleton width="100%" height="7.2rem" :loading="true"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { Skeleton } from 'vue-loading-skeleton'
import maidCover from '@/assets/images/maid-cover-1.jpg'

@Component({
  components: { Skeleton },
})
export default class LoadingMaid extends Vue {
  // maid_cover = maidCover
  maid_cover = 'https://dev.houseofdev.tech/assets/images/maid-cover-1.jpg'
}
</script>

<style lang="scss" scoped>
/* override class */
.disable-view {
  cursor: not-allowed;
}

.card-skeleton {
  height: auto;
  min-height: 450px !important;
}

.card-maid {
  @media (min-width: 768px) {
    min-height: 334px;
  }
}
.card-footer {
  border: none;
  padding: 0;
}
.btn {
  border-radius: 0;
}
.fav-icon {
  position: absolute;
  top: 4px;
  right: 16px;
  color: #ff695b;
  font-size: 1.75em;
}
.friend_avatar {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-left: -8px;
}
.more-friend {
  z-index: 1;
  background-color: #fb6107;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-left: -8px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
}
i {
  color: #fff !important;
  cursor: pointer;
  &.text-red {
    color: #ff695b !important;
  }
}

.maid-desc {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-height: 62px;
}

.cover-image {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
@media only screen and (min-width: 300px) {
  .vaccine {
    width: 90px !important;
    height: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .vaccine {
    width: 64px !important;
    height: 20px;
  }
}
</style>
