<template>
  <div class="article-card d-flex flex-column">
    <div>
      <b-img
        :src="require('../assets/images/maid-cover-01.jpg')"
        style="max-width: 265px; width: 100%;"
      ></b-img>
    </div>
    <div class="d-flex flex-column justify-content-between p-4">
      <p>Article Name</p>
      <p>Writer Name</p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard
      </p>
      <div class="d-flex justify-content-between">
        <p>Date : 23/11/2019</p>
        <b-link class="text-dark">Read more</b-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0.5rem;
}
</style>
